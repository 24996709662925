<template>
    <div class="box contact" :class="{'is-active':isSelected}" @click.self="$emit('toggleContact',contact);toggleContact" >
        <img :src="contact.image" class="contact-image" width="48" v-if="contact.image"  >
        <span v-else class="contact-image  contact-image--placeholder">{{ initials }}</span>
        <p class="contact-name"  @click.stop="$emit('toggleContact',contact);toggleContact">{{ contact.firstName }} <strong>{{ contact.lastName }}</strong>
            <br /><span class="contact-info">{{contact.division}}</span>
        </p>
        <div class="contact-actions">
            <a class="contact-action contact-action--phone" v-if="contact.telephone"
               :href="'tel:' + contact.telephone"><inline-svg :src="require('@/assets/images/ic_phone.svg')" width="24"/></a>
            <a class="contact-action contact-action--email"
               v-if="contact.email" :href="'mailto:' + contact.email"><inline-svg :src="require('@/assets/images/ic_email.svg')" width="24" /></a>
            <span class="contact-action contact-action--favorite" @click.stop="setFavorite">
                <inline-svg :src="AddFavoriteIcon" width="24"/>
            </span>
        </div>
    </div>
</template>


<script>
import { FAV_TOGGLE } from '../../utils/store/actions/favorites';
import { mapGetters } from 'vuex';

export default {
    name: "ContactBox",
    data() {
        return {
            'favorite': false,
            isOpen: false
        };
    },

    props: {
		selected:{
			type: [Object, Boolean],
            default: false
        },
        contact: {
            // type: Object || Boolean,
            required: true
        },
        open: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters(['favorites/isFavorite']),
		initials: function(){
			let cnt = this.contact;
			return cnt.firstName.substr(0,1) + cnt.lastName.substr(0,1);
        },
        isSelected: function(){
			if( ! this.selected ) return false;

			return ( this.selected.uid === this.contact.uid );
        },
        isFavorite: function () {
            return this['favorites/isFavorite']({group: 'contacts', uid: this.contact.uid});
        },
        AddFavoriteText: function () {
            return this.isFavorite ? "Von Favoriten entfernen" : "Zu den Favoriten hinzuf&uuml;gen";
        },
        AddFavoriteIcon: function () {
            return this.isFavorite ? require("@/assets/images/ic_star_full.svg") : require("@/assets/images/ic_star_empty.svg");
        }
    },
    methods: {
        toggleContact: function () {
				this.isOpen = !this.isOpen;
				this.$emit('contactToggled', true, this.contact);
        },
        setFavorite: function ( ) {
			let user = this.$store.getters.getProfile;
			this.$store.dispatch('favorites/'+FAV_TOGGLE, {group: 'contacts', contact: this.contact, user: user.uid});
        }
    },
    watch: {}
};
</script>

<style scoped>


</style>
