<template>
    <div :class="{'is-loading' : isLoading}" id="kontakte">
        <Teleport to="#sidebar" :disabled="contactView === 'mobile'">
        <div class="page-title">
            <h2>Profile</h2>
            <p>Hier findet ihr alle Kolleginnen und Kollegen, die &bdquo;Wir sind NEW&ldquo; bereits nutzen.</p>
        </div>
        <div class="search">
            <label>
                <input type="search" v-model="fullTextFilter" placeholder="Kontakte durchsuchen" />
                <span>Suche</span>
                <inline-svg :src="require('@/assets/images/ic_person_search.svg')" />
            </label>
        </div>

        <ul class="list list--filter">
            <li @click="scrollToDirect( 'favorites' )"
                v-if="favList.length"
                class="list-item list-item--favorites"><inline-svg :src="require('@/assets/images/ic_star_full.svg')" class="icon" /></li>
            <li @click="scrollToDirect(char)" class="list-item" v-for="(char ,key) in alphabet"
                :disabled="char.disabled" v-bind:key="key">{{char.char}}
            </li>
            <li @click="scrollToDirect('#')" disabled="true" class="list-item">#</li>
        </ul>

        <ul class="list list--contact">
            <template v-if="favList.length > 0">
                <li class="list-item list-item--header" id="favorites">Favoriten</li>
                <template v-for="(contact,idx) in favList" :key="idx">
                    <li class="list-item"  >
                        <contact-box :contact="contact" @toggleContact="showContact" :selected="selectedContact"></contact-box>
                    </li>
                </template>
            </template>
            <template v-for="(group,idx) in contactsList" :key="idx">
                <template v-if="idx !== 'favorites'">
                <li class="list-item list-item--header" :id="idx">{{ idx }}</li>
                <li class="list-item" v-for="(contact,cidx) in group"  :key="cidx">
                    <contact-box :contact="contact" @toggleContact="showContact" :selected="selectedContact"></contact-box>
                </li>
                </template>
            </template>
        </ul>
        </Teleport>
        <lightbox :is-visible="selectedContact !== false" @close="selectedContact=false" v-if="contactView === 'mobile'">
            <template v-slot:content>
                <ProfilSingle :contact="selectedContact" @close="selectedContact = false" v-if="selectedContact !== false" />
            </template>
        </lightbox>
        <ProfilSingle v-else-if="selectedContact" :contact="selectedContact" />
    </div>
</template>

<script>
import api, { ApiDefaults } from '@/utils/api_call';
import ContactBox from "@/components/Subparts/ContactBox";
import Lightbox from '@/components/Plugins/Lightbox';
import ProfilSingle from './ProfilSingle';
import _ from 'lodash';
import EventsBus from '../../utils/EventsBus';
import { STORE_CONTACTS } from '../../utils/store/actions/general';
import { mapGetters } from 'vuex';

export default {
	name: "KappKontakte",
	data() {
		return {
			alphas: _.range(65, 91),
			fullTextFilter: '',
			// contacts: [],
			isLoading: true,
			error: false,
			selectedContact: false
		};
	},
	components: {
		'contact-box': ContactBox,
		ProfilSingle,
		'lightbox': Lightbox
	},
	directives: {},
	methods: {
		showContact: function(contact) {
            this.selectedContact = contact;
        },
		// toggleSelectedContact: function ( cntct ) {
		// 	this.selectedContact = cntct;
		// 	EventsBus.trigger('overlay:open');
		// },
		// allowScroll: function () {
		// 	this.$el.style.overflow = 'auto';
		// },
		// scrollTo: function ( evt ) {
		// 	this.$el.style.overflow = 'hidden';
		// 	let scrollTarget = document.elementFromPoint(window.innerWidth - 15, evt.center.y);
        //
		// 	if ( scrollTarget.innerText !== '#' && scrollTarget.getAttribute('disabled') === null ) {
		// 		let el = document.querySelector('.list-item--header#' + scrollTarget.innerText.toUpperCase());
		// 		document.querySelector('html').scrollTop = (el.offsetTop - 82);
		// 	}
		// },

		scrollToDirect: function ( scrollTarget ) {
			if ( scrollTarget !== 'favorites' ) {
				scrollTarget = scrollTarget.char;
			}
			let trgt = document.querySelector('.list-item--header#' + scrollTarget),
                wrp;//document.querySelector('#app');
            if( this.contactView === 'mobile' ) {
				wrp = window;
            }
			else {
				wrp = document.querySelector('#sidebar');
            }

			wrp.scrollTo({ top : trgt.offsetTop, behavior: 'smooth' });
		},

		fetchData: function () {

			let apiParams = Object.assign({}, ApiDefaults, {
				params: {
					id: 7,
                    type: 10,
					'tx_c3json_jsondatauser[action]': 'list'
				}
			});

			api(apiParams)
				.then(( response ) => {
					if ( response.status === 200 ) {
						let contacts = response.data.user;

						contacts.map(( a ) => {
							a.username = '';
							a.open = false;
							return a;
						});

						contacts = contacts.sort(( a, b ) => {
							if ( a.lastName === b.lastName ) {
								return a.firstName.localeCompare(b.firstName);
							}
							return a.lastName.localeCompare(b.lastName);
						});

						// this.contacts = contacts;
						this.$store.commit( STORE_CONTACTS, contacts );

						if( window.matchMedia('(min-width: 1024px)').matches ) {
							this.selectedContact = response.data.user[0];
                        }
					}
				})
				.catch(( err ) => {
					this.error = 'Leider ist ein Fehler beim Laden aufgetreten!';
					console.warn(err);
				})
				.finally(() => {
					this.isLoading = false;
				});
		}
	},
	computed: {
		alphabet: function () {
			let contacts = this.contactsList;
			return _.map(this.alphas, function ( a ) {
				let isDisabled = !Object.hasOwnProperty.call(contacts, String.fromCodePoint(a).toUpperCase());
				return {char: String.fromCodePoint(a), disabled: isDisabled};
			}.bind(contacts));
		},

		favList: function () {
			let list = this.contactsList;
			return list.favorites || [];
		},
		contactsList: function () {
			let contacts = this.contacts,
				favorites = [];

			if ( this.fullTextFilter.toString().length > 2 ) {
				contacts = contacts
					.filter(
						cont => (cont.firstName + ' ' + cont.lastName + ' ' + cont.work_position)
							.toLowerCase()
							.includes(this.fullTextFilter.toLowerCase())
					);
			}

			contacts = _.groupBy(contacts, ( item ) => {
				if ( this.$store.getters['favorites/isFavorite']({group: 'contacts', uid: item.uid}) ) {
					favorites.push(item);
				}
				return item.lastName.charAt(0);
			});

            contacts.favorites = favorites;
			return contacts;
		},
        contactView: function(){
			return window.matchMedia('(max-width:1023px)').matches ? 'mobile' : 'desktop';
        },
		// showContact: function () {
		// 	return this.selectedContact;
		// }
        ...mapGetters(['contacts'])
	},
	created() {
		this.fetchData();
		EventsBus.on('overlay:closed', () => {this.fullTextFilter = ''; this.selectedContact = false;});

		EventsBus.on('lightbox:closed', () => {
			this.selectedContact = false;
		});

	}
};
</script>

<style scoped>

#kontakte {

}

#kontakte::after {
    content: '';
    clear: both;
    display: table;
}

.profile.profile--single{
    grid-column: 3;
    grid-row: 1 / span 3;
}

</style>
